.App {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.letrero {
  background-color: #000000;
}

.logo {
  outline: none;
  background-color: #ffffff;
  max-width: 130px;
  max-height: 130px;
  color: white;
  margin-right: 20px;
}

.logo:hover {
  outline: none;
  background-color: #ffffff;
  max-width: 130px;
  max-height: 130px;
  color: white;
}

.logo:active {
  outline: none;
  background-color: #ffffff;
  max-width: 130px;
  max-height: 130px;
  color: white;
}

.logo:focus {
  outline: none;
  background-color: #ffffff;
  max-width: 150px;
  max-height: 150px;
  color: white;
}

.logo-button {
  outline: none;
  text-align: center;
  background-color: #ffffff;
  border: none;
  color: white;
  margin-left: 20px;
  margin-right: 50px;
}
.logo-button:active {
  outline: none;
  background-color: #ffffff;
  border: none;
  color: white;
}
.logo-button:focus {
  outline: none;
  background-color: #ffffff;
  border: none;
  color: white;
}

.logo-button:hover {
  outline: none;
  background-color: #ffffff;
  border: none;
  color: white;
}

.navbar {
  background-color: #ffffff;
}

.iniciar-sesion {
  font-size: 16px;
  text-align: center;
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 8px;
  color: black;
  width: 160px;
  margin-right: 25px;
}

.iniciar-sesion:hover {
  font-size: 16px;
  background-color: #ffffff;
  border-color: rgb(255, 255, 255);
  border-radius: 8px;
  color: #0300c3;
}

.crea-meraki-borde-negro {
  font-size: 16px;
  text-align: center;
  background-color: #2b2b2b;
  border-radius: 18px;
  border-color: #ffffff;
  color: #ffffff;
  width: 200px;
}
.crea-meraki-borde-negro:hover {
  font-size: 16px;
  background-color: #909090;
  border-color: #ffffff;
  border-radius: 18px;
  color: #000000;
}

.crea-meraki-borde-blanco {
  font-size: 16px;
  text-align: center;
  background-color: #2b2b2b;
  border-radius: 18px;
  border-color: #000000;
  color: #ffffff;
  width: 200px;
}
.crea-meraki-borde-blanco:hover {
  font-size: 16px;
  background-color: #909090;
  border-color: #000000;
  border-radius: 18px;
  color: #000000;
}

.crea-meraki {
  font-size: 16px;
  text-align: center;
  background-color: #2b2b2b;
  border-radius: 18px;
  border-color: #000000;
  color: #ffffff;
  width: 200px;
}
.crea-meraki:hover {
  font-size: 16px;
  background-color: #909090;
  border-color: #000000;
  border-radius: 18px;
  color: #000000;
}

.central-buttons {
  font-size: 12px;
  text-align: center;
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 8px;
  color: black;
  width: 160px;
}

.central-buttons {
  font-size: 14px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  color: black;
  width: 130px;
}

.central-buttons:hover {
  font-size: 14px;
  background-color: #ffffff;
  border-color: rgb(255, 255, 255);
  border-radius: 8px;
  color: #0300c3;
}

.right-buttons {
  font-size: 18px;
  margin-right: 20px;
  color: black;
}

.welcome-div {
  background-color: #fff385;
}

.welcome-container {
  text-align: center;
}

.letrero-container {
  position: sticky;
  margin-top: 50px;
  text-align: center;
}

.welcome-image {
  margin-left: -40px;
  width: 395px;
  height: 420px;
}

.company-button {
  font-size: 16px;
  text-align: center;
  color: black;
  border-color: black;
  border-radius: 8px;
  border: 2px solid #000000;
  width: 180px;
}

.company-button:hover {
  font-size: 16px;
  color: rgb(255, 255, 255);
  background-color: #2b2b2b;
  border-color: black;
  border-radius: 8px;
  border: 2px solid #000000;
  width: 180px;
}

.left-welcome-row {
  align-content: center;
  margin-left: 10px;
}

.titulo {
  display: inline-block;
}

.example-titulo {
  color: rgb(255, 255, 255);
  display: inline-block;
}

.comentario {
  display: inline-block;
}

.example-comentario {
  color: white;
  padding-top: 30px;
  display: inline-block;
}

.example-div {
}

.empezar-meraki {
  padding-bottom: 25px;
  display: inline-block;
}

.welcome-left-col {
  justify-content: center;
  text-align: center;
  margin-top: 10px;
}

.first-part-welcomne-row {
  text-align: left;
}

.second-part-welcomne-row {
  text-align: left;
  padding-top: 20px;
}

.login-container {
  margin-top: 100px;
  text-align: center;
  margin-bottom: 150px;
}

.login-left-row {
  background-color: #d20909;
}

.login-left-col {
  background-color: #a3dbe8;
}

.login-right-col {
  padding-top: 20px;
  background-color: #ffffff;
}

.login-internel-right-row {
  text-align: center;
  padding-bottom: 20px;
}

.login-button {
  font-size: 14px;
  text-align: center;
  background-color: #2b2b2b;
  border-radius: 18px;
  border-color: #000000;
  color: #ffffff;
  width: 160px;
}
.login-button:hover {
  font-size: 14px;
  background-color: #909090;
  border-color: #000000;
  border-radius: 18px;
  color: #000000;
}

.register-button {
  font-size: 14px;
  text-align: center;
  background-color: #2b2b2b;
  border-radius: 18px;
  border-color: #000000;
  color: #ffffff;
  width: 160px;
}
.register-button:hover {
  font-size: 14px;
  background-color: #909090;
  border-color: #000000;
  border-radius: 18px;
  color: #000000;
}

.welcome-crea-un-meraki {
  display: inline-block;
  margin-left: 10px;
}

.welcome-company {
  margin-left: 10px;
}

.left-how-work-row {
  margin-left: 10px;
}

.how-work-div {
  background-color: #ffbdc6;
}

.how-work-container {
  padding-top: 50px;
  text-align: center;
}

.how-work-tittle {
  text-align: left;
}

.separador {
  border: 10px solid rgb(0, 0, 0);
  border-radius: 5px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.number1 {
  text-align: center;
  margin-top: 10px;
  max-width: 150px;
  max-height: 150px;
}

.number1-col {
  justify-content: center;
}

.number-div {
  justify-content: center;
  text-align: center;
}

.number2 {
  max-width: 1500px;
  max-height: 150px;
}

.number3 {
  margin-top: 15px;
  max-width: 1500px;
  max-height: 150px;
}

.how-work-images {
}

.navbar {
  box-shadow: 0px 6px 4px rgb(126, 126, 126);
}

.accept-button {
  font-size: 16px;
  text-align: center;
  background-color: #189100;
  border-color: #189100;
  border-radius: 8px;
  color: #ffffff;
  width: 100px;
  margin-right: 20px;
}

.accept-button:hover {
  font-size: 16px;
  text-align: center;
  background-color: #189100;
  border-color: #189100;
  border-radius: 8px;
  color: #ffffff;
  width: 100px;
}

.cancel-button {
  font-size: 16px;
  text-align: center;
  background-color: #910000;
  border-color: #910000;
  border-radius: 8px;
  color: #ffffff;
  width: 100px;
  margin-left: 10px;
}

.cancel-button:hover {
  font-size: 16px;
  text-align: center;
  background-color: #910000;
  border-color: #910000;
  border-radius: 8px;
  color: #ffffff;
  width: 100px;
}

.example-container {
  text-align: center;
}

.example-div {
  background-color: #2b2b2b;
}

.example-images {
  margin-left: 10px;
  padding-top: 100px;
  text-align: center;
  padding-bottom: 50px;
}

.example-buttons {
  text-align: center;
}

.example {
  max-width: 800px;
  max-height: 800px;
}

.cumple {
  align-items: center;
}

.example-button-col {
  width: 100px;
}

.example-image-col {
  text-align: right;
  width: 40px;
}

.cumple-button {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  background-color: #fffc95;
  color: black;
  border-color: black;
  border-radius: 18px;
  border: 2px solid #fffc95;
  width: 180px;
  height: 45px;
  margin-top: 20px;
}

.cumple-button:hover {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  background-color: #fffc95;
  color: black;
  border-color: black;
  border-radius: 18px;
  border: 2px solid #000000;
  width: 180px;
}

.navidad-button {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  background-color: #dcafff;
  color: black;
  border-color: black;
  border-radius: 18px;
  border: 2px solid #dcafff;
  width: 180px;
  height: 45px;
  margin-top: 20px;
}

.navidad-button:hover {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  background-color: #dcafff;
  color: black;
  border-color: black;
  border-radius: 18px;
  border: 2px solid #000000;
  width: 180px;
}

.aniversario-button {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  background-color: #96fe94;
  color: black;
  border-color: black;
  border-radius: 18px;
  border: 2px solid #96fe94;
  width: 180px;
  height: 45px;
  margin-top: 20px;
}

.aniversario-button:hover {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  background-color: #96fe94;
  color: black;
  border-color: black;
  border-radius: 18px;
  border: 2px solid #000000;
  width: 180px;
}

.boda-button {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  background-color: #ffb595;
  color: black;
  border-color: black;
  border-radius: 18px;
  border: 2px solid #ffb595;
  width: 180px;
  height: 45px;
  margin-top: 20px;
}

.boda-button:hover {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  background-color: #ffb595;
  color: black;
  border-color: black;
  border-radius: 18px;
  border: 2px solid #000000;
  width: 180px;
}

.arrow {
  margin-left: 20px;
  max-width: 50px;
  max-height: 20px;
}

input.input-error,
select.input-error {
  border-color: red;
  border-radius: 18px;
  border-width: 1px;
  width: 200px;
  height: 40px;
  padding-left: 15px;
  margin-top: 20px;
  margin-right: 20px;
}
input.input-email {
  border-radius: 18px;
  border-width: 1px;
  width: 200px;
  height: 40px;
  padding-left: 15px;
  margin-top: 20px;
  margin-right: 20px;
}

input.input-name {
  border-radius: 18px;
  border-width: 1px;
  width: 200px;
  height: 40px;
  padding-left: 15px;
  margin-right: 20px;
  margin-top: 20px;
}

input.input-lastname {
  border-radius: 18px;
  border-width: 1px;
  width: 200px;
  height: 40px;
  padding-left: 15px;
  margin-top: 20px;
  margin-right: 20px;
}

input.contact-input-error{
  border-color: red;
  border-radius: 18px;
  border-width: 1px;
  width: 180px;
  height: 40px;
  padding-left: 15px;
  margin-top: 20px;
  margin-right: 20px;
}

select.contact-input-error {
  border-color: red;
  border-radius: 18px;
  border-width: 1px;
  width: 180px;
  height: 40px;
  padding-left: 15px;
  margin-top: 20px;
  margin-right: 20px;
}
input.contact-input-email {
  border-radius: 18px;
  border-width: 1px;
  width: 180px;
  height: 40px;
  padding-left: 15px;
  margin-top: 20px;
  margin-right: 20px;
}

input.contact-input-name {
  border-radius: 18px;
  border-width: 1px;
  width: 180px;
  height: 40px;
  padding-left: 15px;
  margin-right: 20px;
  margin-top: 20px;
}

input.contact-input-lastname {
  border-radius: 18px;
  border-width: 1px;
  width: 180px;
  height: 40px;
  padding-left: 15px;
  margin-top: 20px;
  margin-right: 20px;
}

textarea.contact-input-error,
select.contact-input-error {
  border-color: red;
  border-radius: 18px;
  border-width: 1px;
  max-width: 380px;
  height: 220px;
  padding-left: 15px;
  margin-top: 20px;
  margin-right: 20px;
}

textarea.contact-input-comment {
  border-radius: 18px;
  border-width: 1px;
  min-width: 380px;
  height: 220px;
  padding-left: 15px;
  margin-top: 20px;
  margin-right: 20px;
}

.login-field-form {
  margin-top: 30px;
  text-align: center;
}

.nav-login-button {
  margin-right: 20px;
}

.checkbox-register {
  margin-right: 5px;
}

.idiomas {
  font-size: 14px;
  padding-right: 20px;
}

.error {
  color: #910000;
  margin-right: 20px;
  text-align: center;
}

.contact-error {
  color: #fb0000;
  margin-right: 20px;
  text-align: center;
  width: 400px;
}

.plan-div {
  background-color: #ffffff;
}

.plan-container {
  padding-top: 50px;
  text-align: center;
}

.plan-images {
  margin-bottom: 50px;
}

.left-plan-row {
  align-content: center;
  margin-left: 10px;
}

.plan-left-col {
  justify-content: center;
  text-align: center;
  margin-top: 10px;
}

.martin {
  border-color: #189100;
  border-width: 2;
}

.happyFace-image {
  padding-top: 20px;
  max-width: 50px;
}
.crown-image {
  padding-top: 20px;
  max-width: 50px;
}
.star-image {
  padding-top: 20px;
  max-width: 50px;
}

.plan-col {
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 20px;
}
.plan-div-col-happy {
  display: inline-block;
  border-style: solid;
  width: 350px;
  height: 500px;
  border-radius: 18px;
  text-align: center;
  box-shadow: 0px 12px 8px rgb(126, 126, 126);
}

.plan-div-col-star {
  display: inline-block;
  border-style: solid;
  width: 350px;
  height: 500px;
  border-radius: 18px;
  background-color: #ffbdc6;
  box-shadow: 0px 12px 8px rgb(126, 126, 126);
}

.plan-div-col-crown {
  display: inline-block;
  border-style: solid;
  width: 350px;
  height: 500px;
  border-radius: 18px;
  background-color: #fff385;
  box-shadow: 0px 12px 8px rgb(126, 126, 126);
}

.empezar-plan {
  font-size: 14px;
  text-align: center;
  background-color: #2b2b2b;
  border-radius: 18px;
  border-color: #000000;
  color: #ffffff;
  width: 160px;
  margin-bottom: 20px;
}
.empezar-plan:hover {
  font-size: 14px;
  background-color: #909090;
  border-color: #000000;
  border-radius: 18px;
  color: #000000;
}

.tick-image {
  margin-right: 8px;
}
.plan-text {
  padding-left: 70px;
  max-width: 400px;
}

.funcionality-div {
  background-color: #9795ff;
}

.funcionality-container {
  text-align: center;
}

.funcionality-images {
  padding-top: 100px;
  text-align: center;
  padding-bottom: 50px;
}

.funcionality-titulo {
  color: rgb(0, 0, 0);
  display: inline-block;
}

.funcionality-comentario {
  color: rgb(0, 0, 0);
  padding-top: 30px;
  display: inline-block;
}

.contac-form-row {
  text-align: center;
  padding-bottom: 20px;
}

.contact-div {
  background-color: #2b2b2b;
}

.contact-container {
  text-align: center;
}

.contact-titulo {
  color: rgb(255, 255, 255);
  display: inline-block;
  text-align: left;
  width: 400px;
}

.contact-form-div {
  color: rgb(255, 255, 255);
  display: inline-block;
  text-align: left;
  width: 400px;
}

.contact-row {
  margin-left: 10px;
  padding-top: 70px;
  text-align: center;
  padding-bottom: 50px;
}

.input-container {
  text-align: center;
}
.contact-input-container {
  text-align: left;
}

.enviar-comment-button {
  font-size: 14px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 18px;
  border-color: #ffffff;
  color: #000000;
  width: 160px;
  margin-bottom: 20px;
}
.enviar-comment-button:hover {
  font-size: 14px;
  background-color: #000000;
  border-color: #ffffff;
  border-radius: 18px;
  color: #ffffff;
}

.contact-enviar-button {
  width: 380px;
  text-align: right;
}
