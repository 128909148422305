body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "Playfair Display";
  font-size: 50px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
}

h2 {
  font-weight: 400;
  font-size: 26px;
  color: black;
  margin-top: 20px;
  margin-bottom: 30px;
}

h3 {
  font-family: "Playfair Display";
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding-top: 15px;
  color: rgb(255, 255, 255);
}

h4 {
  font-family: "Playfair Display";
  font-size: 40px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
}

h5 {
  font-size: 16px;
  font-weight: 300;
  padding-top: 10px;
  padding-bottom: 30px;
  color: black;
  text-align: left;
}

h6 {
  font-weight: 500;
  font-size: 16px;
  padding-top: 30px;
  color: black;
}
